<template>
  <Dialog
    :visible="visible"
    :style="{ width: '50vw', 'max-width': '500px' }"
    :breakpoints="{ '640px': '100vw' }"
    :closable="false"
    :header="`Edit '${form.name.value}'`"
  >
    <form
      class="dialog-body"
      @submit.prevent="submit"
    >
      <text-input
        v-model="form.name.value"
        class="mb-4"
        input-id="name"
        label="Name"
        :error-text="nameErrorText"
        :disabled="$isLoading('updateAnalyzerTeamRecord')"
      />
    </form>

    <div class="dialog-buttons">
      <loading-spinner
        v-if="$isLoading('updateAnalyzerTeamRecord')"
        :size-class="'fa-2x'"
      />

      <button
        :disabled="$isLoading('updateAnalyzerTeamRecord')"
        @click="closeDialog"
      >
        Cancel
      </button>

      <button
        class="btn-primary"
        :disabled="!formValid || $isLoading('updateAnalyzerTeamRecord')"
        @click="submit"
      >
        Edit
      </button>
    </div>
  </Dialog>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { deepUnref } from 'vue-deepunref';
import { loadable } from 'vue-is-loading';
import { getErrorMessage } from '#lib/Errors';
import { fieldErrorText, applyErrors } from '#ui/lib/forms';
import { successToast, errorToast } from '#ui/lib/toast';
import TextInput from '#ui/components/TextInput';
import LoadingSpinner from '#ui/components/LoadingSpinner';
import {
  AnalyzerTeamRecordValidator,
  ANALYZER_TEAM_RECORD_FORM_FIELDS,
} from '#features/analyzerTeamRecords/lib/validators/AnalyzerTeamRecordValidator';
// Setup
const store = useStore();
const id = ref(0);
const form = {
  name: ref(''),
};
// Validation
const apiErrors = {
  name: ref([]),
};
const validator = new AnalyzerTeamRecordValidator(ANALYZER_TEAM_RECORD_FORM_FIELDS);
const nameErrorText = computed(fieldErrorText('name', validator, form, apiErrors));
const formValid = computed(() => validator.valid(form));
const resetApiErrors = () => {
  apiErrors.name.value = [];
};
// Open/close
const visible = ref(false);
const openDialog = (analyzerTeamRecord) => {
  id.value = analyzerTeamRecord.id;
  form.name.value = analyzerTeamRecord.name;
  visible.value = true;
};
const closeDialog = () => {
  form.name.value = '';
  apiErrors.name.value = [];
  visible.value = false;
};
defineExpose({ openDialog, closeDialog });
// Edit AnalyzerTeamRecord
const updateAnalyzerTeamRecord = loadable(
  (values) => store.dispatch('AnalyzerTeamRecords/updateAnalyzerTeamRecord', values),
  'updateAnalyzerTeamRecord',
  getCurrentInstance(),
);
const submit = async () => {
  resetApiErrors();
  if (! validator.valid(deepUnref(form))) {
    applyErrors(apiErrors, validator.errors(deepUnref(form), apiErrors));
    return;
  }
  try {
    await updateAnalyzerTeamRecord({ id: id.value, ...deepUnref(form) });
    closeDialog();
    successToast('Analyzer Team Record updated.');
  }
  catch (error) {
    errorToast(getErrorMessage(error, 'Could not update Analyzer Team Record.'));
  }
};
</script>
