<template>
  <div class="card">
    <h2 class="card-header">
      analyzerTeamRecords
    </h2>
    <div class="card-body">
      <DataTable
        :fields="analyzerTeamRecordFields"
        :rows="currentPage"
        :total-pages="totalPages"
        :search-enabled="true"
        :load-list="getList"
        :is-loading="$isLoading('getListAnalyzerTeamRecord')"
        :actions="analyzerTeamRecordActions"
        :empty-message="'No analyzerTeamRecords'"
        class="w-full"
      />

      <div class="flex flex-row-reverse mt-4">
        <button
          :disabled="$isLoading('getListAnalyzerTeamRecord')"
          @click="$refs.newAnalyzerTeamRecordDialog.openDialog()"
        >
          New Analyzer Team Record
        </button>
      </div>
    </div>

    <NewAnalyzerTeamRecordDialog ref="newAnalyzerTeamRecordDialog" />
    <EditAnalyzerTeamRecordDialog ref="editAnalyzerTeamRecordDialog" />
    <DeleteAnalyzerTeamRecordDialog ref="deleteAnalyzerTeamRecordDialog" />
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import omit from 'lodash/omit';
import { loadable } from 'vue-is-loading';
import DataTable from '#ui/components/DataTable';
import { ANALYZER_TEAM_RECORD_MODEL_FIELDS } from '#features/analyzerTeamRecords/lib/models/AnalyzerTeamRecordModel';
import NewAnalyzerTeamRecordDialog from '#features/analyzerTeamRecords/ui/dialogs/NewAnalyzerTeamRecordDialog';
import EditAnalyzerTeamRecordDialog from '#features/analyzerTeamRecords/ui/dialogs/EditAnalyzerTeamRecordDialog';
import DeleteAnalyzerTeamRecordDialog from '#features/analyzerTeamRecords/ui/dialogs/DeleteAnalyzerTeamRecordDialog';
// Setup
const store = useStore();
const currentPage = computed(() => store.state.AnalyzerTeamRecords.currentPage);
const totalPages = computed(() => store.state.AnalyzerTeamRecords.totalPages);
const analyzerTeamRecordFields = computed(() => omit(ANALYZER_TEAM_RECORD_MODEL_FIELDS, 'id'));
// List navigation
const getList = loadable(
  (values) => store.dispatch('AnalyzerTeamRecords/getList', values),
  'getListAnalyzerTeamRecord',
  getCurrentInstance(),
);
  // Row actions
const editAnalyzerTeamRecordDialog = ref(null);
const openEditDialog = (analyzerTeamRecord) => {
  editAnalyzerTeamRecordDialog.value.openDialog(analyzerTeamRecord);
};
const deleteAnalyzerTeamRecordDialog = ref(null);
const openDeleteDialog = (analyzerTeamRecord) => {
  deleteAnalyzerTeamRecordDialog.value.openDialog(analyzerTeamRecord);
};
const analyzerTeamRecordActions = [
  { name: 'Edit Analyzer Team Record', fn: openEditDialog },
  { name: 'Delete Analyzer Team Record', fn: openDeleteDialog },
];
</script>
